.number-comments-card {
  max-width: 250px;
}

.image-container {
  position: relative;
  width: 120px; 
  height: 68px; 
  overflow: hidden;
}

.image-container a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.base-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  transform: translate(-50%, -50%);
}

.overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
}

.image-container:hover .overlay-image {
  opacity: 1; /* Show on hover */
}

.header-text {
  min-width: 100px; /* adjust as necessary */
  overflow: visible;
  position: relative;
  z-index: 1; /* ensure it is above adjacent content */
  white-space: nowrap; /* to keep the text in one line */
  display: flex;
  justify-content: flex-end;
}

.page-description {
  max-width: 300px;
}

.approve-comment-button {
  width: 180px;
  height: 44px;
  background-color: var(--bs-light);
  border-style: solid;
  border-color: var(--bs-dark);
  border-radius: 5px;
  color:black;
}

.approve-comment-button-selected {
  background-color: var(--bs-primary);
  border-style: solid;
  border-color: var(--bs-primary);
  border-radius: 5px;
  color:white;
}

.gear-icon {
  height: 44px;
  width:  44px;
}

.max-width-50px {
  max-width: 50px;
  /* If you want to make sure the content inside doesn't overflow, you can add: */
  overflow: hidden;
}

.clickable {
  cursor: pointer;
}

.done-pic {
  width: 232px;
  height: 275px;
}

.thumbnail-comment-header {
  display: flex;
}

.thumbnail-comment-title {
  max-width: 250px;
  max-height: 60px; 
  overflow: hidden;
  font-size: 18px;
  /* font-weight: 400; */
  line-height: 20px;
}

.thumbnail-comment-image {
  width: 120px;
}

/* Default styles */
.CommentRowComponent {
  display: flex;
}

.stickyHeader {
  display: flex;
}

.small-svg {
  height: 50px; /* Adjust the value as needed */
  width: 100%;
}

.big-checkbox {
  transform: scale(2);
}

.commentsFooter {
  height: 100px;
}

.CommentsTable {
  padding-bottom: 100px;
}

.commentsTableColumn1 {
  width: 2%;
}

.commentsTableColumn2 {
  width: 41%;
}

.commentsTableColumn3 {
  width: 4%;
}

.commentsTableColumn4 {
  width: 45%;
}

.commentsTableColumn5 {
  width: 8%;
  min-width: 90px;
}

.mobile-only {
  display: none;
}

.non-mobile-only {
  display: block;
}

.comment-footer {
  padding: 0px;
}
  

@media (max-width: 1024px) {

  
.commentsTableColumn1 {
  width: 1%;
}

.commentsTableColumn2 {
  width: 42%;
}

.commentsTableColumn3 {
  width: 4%;
}

.commentsTableColumn4 {
  width: 45%;
}

.commentsTableColumn5 {
  width: 8%;
  min-width: 90px;
}

} 

  /* Mobile-friendly styles */
@media (max-width: 768px) {

    .big-checkbox {
      transform: scale(2);
    }

    .CommentRowComponent {
      flex-direction: column;
    }

    .comment-footer {
      padding: 0px;
    }

    .mobile-only {
        display: block;
      }


    .non-mobile-only {
      display: none;
    }

    .approve {
        padding-right: 10px;
    }
  
    .commentsTableColumn1,
    .commentsTableColumn2,
    .commentsTableColumn3,
    .commentsTableColumn4,
    .commentsTableColumn5 {
      width: 100%;
    }

    .commentsTableColumn5 {
        padding-top: 5px;
    }
    
    .small-svg {
      height: 30px; /* Adjust the value as needed */
      transform: rotate(90deg);
    }
  
    .commentsFooter {
      height: 80px;
    }
  
    .CommentsTable {
      padding-bottom: 80px;
    }
  }