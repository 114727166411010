
:root {
    --color-secondary: rgb(234, 234, 234); 
    --color-primary: rgb(234, 234, 234); 



    /* looks nice --color-primary: rgb(158, 212, 241);
    --color-secondary: rgb(234, 234, 234);  */


    
    /* super old --color-primary: rgba(0, 255, 173, 1.0);
    --color-secondary: rgba(255, 255, 255, 1.0);  */
    --color-white: rgba(255, 255, 255, 1.0);
    --blue-color: rgb(25, 36, 64);
    --green-color:  #00ffad;
}

.striked-text {
    color: white;
    text-decoration-line: line-through;
    text-decoration-color: red;  /* Red strikethrough line */
    text-decoration-style: solid;
}

/* animations */
.fade-in-animation {
    opacity: 0;
    transition: opacity 1s;
}
.fade-in-animate {
    opacity: 1;
    transition-delay: 500ms;
}

.animation0 {
    opacity: 0;
    transition: opacity 0.2s;
}
.animate0 {
    opacity: 1;
}

.animation1 {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s, transform 1s;
}
.animate1 {
    opacity: 1;
    transform: translateY(0);
    transition-delay: calc(var(--order) * 200ms);
}

.animation2 {
    opacity: 0;
    transform: translateX(calc(var(--sign) * 100px)); 
    transition: opacity 1s, transform 1s;
}
  
.animate2,
.animate2 {
    opacity: 1;
    transform: translateX(0); 
}

.animation3 {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s, transform 1s;
}
.animate3 {
    opacity: 1;
    transform: translateY(0);
}

body {
    margin: 0;
    color: black;
    background: white;
}

.marketing-container {
    padding: 0;
}

section {
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    min-height: 400px;
    padding: 100px 15vw;
    text-align: center;
}

.marketing-h1 {
    font-weight: 500;
    font-size: 72px;
}

.hero-section {
    padding-top:25px;
    padding-bottom:25px;
    /* padding: 25px 15vw; */
}

.hero-section {
    color: white;
    background-image: 
        linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url('../../assets/marketing-background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.marketing-section-2 {
    position: relative;
    background: linear-gradient(168deg, var(--color-primary) 55%, var(--color-secondary) 0);
}

.marketing-section-features {
    position: relative;
    background: linear-gradient(168deg, var(--color-secondary) 55%, var(--color-primary) 0);
}

.marketing-section-4 {
    color: white;
    background-image:  
        linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        url('../../assets/marketing-background2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
/* 
.marketing-underlay {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0px 5px;
    z-index: 0; 
    border-radius: 4px; 
    display: flex;
    align-items: center;\
    justify-content: center;
    flex-direction: column;
} */

.marketing-section-4::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
    z-index: -1; /* Ensures the overlay is below the content */
}


.mac-mockup {
    width: clamp(300px, 700px, 90%);
    height: auto;
    aspect-ratio: 1.65 / 1;
}

.developed-with-yt {
    width: 350px;
    height: 125;
}

.grid-icons {
    width: 80px;
    height: auto;
    margin: 30px;
}

.marketing-top-grid p {
    padding:20px;
}

.call-to-action {
    background-color: var(--blue-color);
    color: white;
    font-weight: 500;
    width: 200px;
    border-radius: 22px;
    border-color: var(--blue-color);
    transition: transform 0.3s linear;

    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.call-to-action:hover {
    /* transform: scale(1.05); */
    transform: translateY(-4px);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background-color: var(--blue-color);
    background-color: black;
}

.marketing-section-3 .call-to-action {
        background-color: var(--blue-color);
        color: white;
}

.marketing-section-3 .call-to-action:hover {
    background-color: black;
}

.marketing-section-4 .call-to-action {
    background-color: var(--green-color);
    border: none;
    color: var(--blue-color);
}

.marketing-section-4 .call-to-action:hover {
    color: white;
}

.marketing-top-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;       
    grid-template-rows: 1fr;            
    place-items: center;
}

.marketing-section-2
{
    text-align: center;
}

.small-h1
{
    font-size: 40px;
    font-weight: 700;
}

.marketing-section-3 p
{
    text-align: left;
}

.marketing-section-2 h2 {
    font-size: 24px;
}

.small-text {
    font-size: 16px;
}

.green {
    background: var(--green-color);
}

.blue {
    background: #3c31dd;
}

.dark {
    background: #0f0f10;
}

.marketing-section-3 {
    display: grid;
    grid-template-columns: 1fr 1fr;       
    grid-template-rows: 1fr;            
    place-items: center;
    justify-content: space-between;
    background: var(--color-primary);
}

.marketing-section-4
{
    align-items: center;
}

.final-call-to-action {
    font-size:32px;
}

.pricing-options {
    background-color: var(--blue-color);
    color:white;
    width:min(380px, 90%);
    text-align: center !important;
    font-size:16px;
}

.small-pricing-label {
    font-weight: 700;
}

.big-number {
    font-size: 88px;
}

@media (max-width: 1080px) {
    section {
        padding: 50px 5vw;
    }
}

@media (max-width: 900px) {
    .marketing-section-2 {
        padding-left:25;
        padding-right:25px;
    }
}

@media (max-width: 1185px) {
    .marketing-section-2 h2 {
        min-height: 58px;
    }
}

@media (max-width:750px) {
    h1 {
        /* font-weight: 500; */
        font-size: 60px;
    }

    .marketing-top-grid {
        display: grid;
        grid-template-columns: 1fr; 
        grid-template-rows:    1fr 1fr 1fr;           
        place-items: center;
    }

    .marketing-section-2 h2 {
        min-height: 0px;
    }


    .marketing-section-3 {
        display: grid;
        grid-template-columns: 1fr;       
        grid-template-rows: 1fr 1fr;            
        place-items: center;
    }

    .marketing-section-4 {
        background-image: url('../../assets/marketing-background2-mobile.png');
    }
}