.App {
  text-align: center;
  font-size:  20px;
}

.settings-section {
  width: 95%;       /* Set the width to 95% of the container/page */
  max-width: 650px; /* But don't let it exceed 650px */
  margin: 0 auto;   /* Center the div if the page width is greater than 650px */
}

.divider {
  border-bottom: 5px solid #8474e2;
  margin: 10px 0;
  width: 25%; /* You can adjust this to your needs */
  margin-left: auto;
  margin-right: auto;
}

.dropdown-menu {
  width: 250px;
}

.progressBarContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #f3f3f3;
  z-index: 1000; 
}

.progressBar {
  height: 5px;
  width: 0%;
  background-color: var(--bs-primary); /* Change to desired color */
}

.settings-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;       
  grid-template-rows: 1fr 1fr;            
  place-items: center;
  gap: 20px;
}

.confused-robot {
  width: 200px;
  height:auto
}

.comments-home-robot, .welcome-ai {
  width: 200px;
  height: 213px;
}

.intelligent-robot {
  width: 150px;
  height: auto;
}

.thumbnail-image {
  width: 150px;
  max-width: 100%;
  height: 85px;
}

.videos-list-image {
  max-width: 150px;
  height: 85px;
}

.videos-list-title, .videos-list-date {
  max-width: 200px;
}

html, body, #root {
  height: 100%;
}

.sticky-top {
  z-index: 900 !important;
}

label, input, button {
   margin: 10px;
   padding: 5px;
}

p {
  text-align: center;
  max-width: clamp(45ch, 50%, 65ch);
}


/**
  Link
**/

a {
  color: #2098f3;
  text-decoration: none;
  word-wrap: break-word;
  cursor: pointer;
}

a:hover {
  color: #40a9ff;
  text-decoration: none;
}

/**
  Button
**/


.button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 4px;
  height: 45px;
  line-height: 45px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  position: relative;
  color: rgba(0,0,0,.65);
  background-color: transparent;
  border-color: #e8e8e8;
  outline: none;
}

.button-block {
  display: block;
  width: 100%;
}

.button-lg {
  padding: 0 30px;
}

.button-primary {
    color: #fff;
    background-color: #2098f3;
    border-color: #2098f3;  
} 

.button-primary:hover, .button-primary:focus, .button-primary:active, .button-primary.active {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.button-success {
    color: #fff;
    background-color: #52c41a;
    border-color: #52c41a;    
}

.button-success:hover, .button-success:focus, .button-success:active, .button-success.active {
  background-color: #52c41a;
  border-color: #52c41a;
}


.button-link {
  border: none;
  height: 34px;
  padding: 0 15px;
}

.button-link:hover {
  background-color: rgba(158,158,158, 0.20);
}


/**
  Form
**/

.form-item {
	margin-bottom: 18px;
}

.form-item .button {
    cursor: pointer;
}

.form-item label {
    font-size: 0.85em;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px;
    color: rgba(0,0,0,.65);
}

.form-control {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 45px;
  font-size: 0.87em;
  line-height: 45px;
  color: rgba(0,0,0,.80);
  background-color: #fff;
  background-image: none;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  transition: all .3s;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}


.form-control:hover, .form-control:focus, .form-control:active {
  border-color: #40a9ff;
  outline: 0;
  border-right-width: 1px!important;
}

.form-control:focus, .form-control:active {
  box-shadow: 0 0 0 2px rgba(24,144,255,.2);    
}

.form-control.invalid {
  border-color: #f5222d;
}

.form-control.invalid:focus, .form-control.invalid:active {
  box-shadow: 0 0 0 2px rgba(245,34,45,.2);        
} 

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: not-allowed;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #f6f8fa;
  opacity: 1;
}

.form-label {
  margin-bottom: 10px;
}

/**
  Or separator
**/

.or-separator {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  position: relative;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 1em;    
}

.or-text {
  position: absolute;
  left: 46%;
  top: 0;
  background: #fff;
  padding: 10px;
  color: rgba(0,0,0,.45);
}

.mobile-only {
  display: none;
}

.non-mobile-only {
  display: block;
}

.mobile-only-inline {
  display: none;
}

.non-mobile-only-inline {
  display: inline;
}

.hide-on-mobile-flex {
  display: flex;
}

.mobile-only-flex {
  display: none !important;
}

/* Mobile-friendly styles */
@media (max-width: 768px) {
  .mobile-only {
    display: block;
  }

  .non-mobile-only {
    display: none;
  }

  .hide-on-mobile-flex {
    display: none !important;
  }

  .mobile-only-inline {
    display: inline;
  }
  
  .non-mobile-only-inline {
    display: none;
  }

  .mobile-only-flex {
    display: flex !important;
  }
}