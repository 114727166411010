li {
    list-style: none;
    text-align: left;
}

.policy p {
    text-align: left;
}

.policy h2 {
    text-align: left;
    padding-top: 20px;
}
