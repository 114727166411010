/* Styles for desktop */
.login-container {
    text-align: center;
  }
  
  .errorMessage {
    background-color: #ffffcc;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ffcc00;
    border-radius: 4px;
    color: #333;
}

.small-signup-text {
  font-size: 0.8em !important;
  text-align: left;
}

.signup-checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}


  .login-content {
    background: #fff;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 2px;
    width: 500px;
    display: inline-block;
    margin-top: 30px;
    vertical-align: middle;
    position: relative;
    padding: 35px;
  }
  
  .social-button {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
  }
  
  .social-button img {
    height: 32px;
    float: left;
    margin-top: 5px;
  }
  
  .social-button.google {
    margin-top: 7px;
    text-decoration: none;
  }
  
  .social-button.facebook img {
    height: 24px;
    margin-left: 3px;
  }
  
  .social-button.github img {
    height: 24px;
    margin-left: 3px;
  }
  
  .signup-link {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
  
  .login-title {
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.65);
  }
  
  /* Styles for mobile */
  @media (max-width: 600px) {
    .login-content {
      width: 100%;
      margin-top: 15px;
      padding: 20px;
    }
  
    .login-title {
      font-size: 1.2em;
      margin-bottom: 20px;
    }
  
    .social-button {
      font-size: 14px;
    }
  
    .social-button img {
      height: 24px;
      margin-top: 6px;
    }
  
    .social-button.google {
      margin-top: 5px;
    }
  
    .social-button.facebook img,
    .social-button.github img {
      height: 18px;
    }
  
    .signup-link {
      font-size: 12px;
    }
  }
  